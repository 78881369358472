import styled from 'styled-components'

export default styled.div`
  height: 100vh;
  background-color: ${(props) => props.theme.darkBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  .set-password-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 400px;
      height: 109px;
    }
    .email-text {
      color: white;
      font-weight: bold;
      font-size: 24px;
      margin-top: 33px;
    }
    .suggest-text {
      color: white;
      font-size: 18px;
      line-height: 1.17;
      max-width: 400px;
      text-align: center;
      margin-top: 10px;
    }

    .invalid-link-text {
      font-size: 18px;
      color: white;
      max-width: 440px;
      margin-top: 140px;
      text-align: center;
      p {
        line-height: 1.17;
        margin: 0;
      }
    }

    .form-wrapper {
      width: 400px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 33px;
      .input-form-wrapper {
        width: 300px;
        &:first-child {
          margin-bottom: 30px;
        }
      }
      .error-wrapper {
        width: 100%;
        height: 53px;
        padding-top: 12px;
        text-align: center;
        font-size: 12px;
        font-style: italic;
        color: ${(props) => props.theme.orangeFont};
      }
      button {
        width: 300px;
        height: 50px;
      }
    }
  }
`
