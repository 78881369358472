export default {
  EN: {
    password_label: 'PASSWORD',
    password_placeholder: 'Enter Password',
    confirm_password_label: 'CONFIRM PASSWORD',
    confirm_password_placeholder: 'Confirm Password',
    confirm_btn: 'Confirm',
    password_hint:
      'The password must be at least 8 characters long and contains at least 1 letter and 1 number. Also excluded special characters for password setting e.g. !"#$%&\'()+,-./:;<=>?@[]^_ { | }~',
    invalid_link_1: 'This URL is invalid or already expired.',
    invalid_link_2: 'Please contact admin for a new URL.',
    logged_in_title: 'Caution',
    logged_in_msg_1: 'You are currently signed in as',
    logged_in_msg_2: 'Please sign out before set a new password.',
    back_to_home_btn: 'Back to home',
    sign_out_btn: 'Sign Out',
    wrong_password_format: 'Your password did not match the suggestion above',
    password_not_match: 'Your password did not match',
    blank_password: 'Password should not be blanked',
    set_password_failed: 'Set password failed',
    set_pass_success_title: 'Success',
    set_pass_success_msg: 'Your password was set successfully. You are now signed in.'
  },
  TH: {
    password_label: 'รหัสผ่าน',
    password_placeholder: 'ใส่รหัสผ่าน',
    confirm_password_label: 'ยืนยันรหัสผ่าน',
    confirm_password_placeholder: 'ใส่รหัสผ่านอีกครั้ง',
    confirm_btn: 'ยืนยัน',
    password_hint:
      'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ตัวอักษร จะต้องมีตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว และตัวเลขอย่างน้อย 1 ตัว. ไม่สามารถใช้อักษรพิเศษเหล่านี้ได้ !"#$%&\'()+,-./:;<=>?@[]^_ { | }~',
    invalid_link_1: 'URL นี้ไม่ถูกต้องหรืออาจจะหมดอายุแล้ว',
    invalid_link_2: 'โปรดติดต่อผู้ดูแลระบบ สำหรับ URL ใหม่',
    logged_in_title: 'ข้อควรระวัง',
    logged_in_msg_1: 'คุณกำลังอยู่ในระบบด้วยอีเมลล์',
    logged_in_msg_2: 'กรุณาออกจากระบบก่อนที่จะตั้งรหัสผ่านใหม่',
    back_to_home_btn: 'กลับไปหน้าแรก',
    sign_out_btn: 'ออกจากระบบ',
    wrong_password_format: 'รหัสผ่านของคุณไม่ตรงกับคำแนะนำด้านบน',
    password_not_match: 'รหัสผ่านของคุณไม่ตรงกัน',
    blank_password: 'รหัสผ่านไม่ควรเว้นว่างไว้',
    set_password_failed: 'ตั้งรหัสผ่านไม่สำเร็จ',
    set_pass_success_title: 'สำเร็จ',
    set_pass_success_msg: 'รหัสผ่านของคุณตั้งสำเร็จแล้ว คุณสามารถเข้าสู่ระบบได้'
  }
}
