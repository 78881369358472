import React from 'react'
import PropTypes from 'prop-types'
import TextInput from '../../components/TextInput/Loadable'
import Button from '../../components/Button/Loadable'
import ModalOverlay from '../../components/ModalOverlay/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import Spinner from '../../components/Spinner/Loadable'
import SetPasswordStyled from './styledComponent'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as setPasswordActions from './actions'
import * as LoginActions from '../LoginPage/actions'

import LogoImg from '../../asset/images/login/logo.svg'
import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FETCH_STATUS_IDLE, FRONTEND_ROUTES } from '../../utils'

import MESSAGE from './message'

const REQUIRE_PASSWORD_LENGTH = 8
const REGEX_PASS = /^(?=.*\d)(?=.*[a-z]).{8,}$/gm
const NOT_FOUND_ERROR_CODE = 404

export class SetPasswordPage extends React.Component {
  state = {
    showErrorText: false,
    errorText: ' ',
    showLoading: false,
    showModal: false,
    modalData: {
      title: '',
      message: ''
    },
    shouldDisabled: false
  }

  async componentDidMount() {
    await this.props.isLoggedIn()
    const registerToken = this.props.location.pathname.split('/set-password/')[1]
    this._loadUserEmailForSetPassword(registerToken)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.getUserEmailStatus === FETCH_STATUS_IDLE && this.props.getUserEmailStatus === FETCH_STATUS_REQUEST) {
      this.showLoading()
    }
    if (prevProps.getUserEmailStatus === FETCH_STATUS_REQUEST && this.props.getUserEmailStatus === FETCH_STATUS_SUCCESS) {
      this.hideLoading()
      this._enableInputAndButton()
    }
    if (prevProps.getUserEmailStatus === FETCH_STATUS_REQUEST && this.props.getUserEmailStatus === FETCH_STATUS_FAILURE) {
      this.hideLoading()
      this._setLoadingUserEmailErrorText()
    }
    if (prevProps.setPasswordStatus === FETCH_STATUS_IDLE && this.props.setPasswordStatus === FETCH_STATUS_REQUEST) {
      this.showLoading()
    }
    if (prevProps.setPasswordStatus === FETCH_STATUS_REQUEST && this.props.setPasswordStatus === FETCH_STATUS_SUCCESS) {
      this.hideLoading()
      this.setShowModalSuccess()
      this.handleRedirectLogin()
    }
    if (prevProps.setPasswordStatus === FETCH_STATUS_REQUEST && this.props.setPasswordStatus === FETCH_STATUS_FAILURE) {
      this.hideLoading()
      this._setShowErrorSetPasswordFailed()
    }
    this._handleFetchLogout(prevProps)
  }

  _loadUserEmailForSetPassword(registerToken) {
    this.props.getUserByToken(registerToken)
  }

  _handleFetchLogout(prevProps) {
    if (prevProps.logoutAPI.fetchStatus === FETCH_STATUS_IDLE && this.props.logoutAPI.fetchStatus === FETCH_STATUS_REQUEST) {
      this.showLoading()
    }
    if (prevProps.logoutAPI.fetchStatus === FETCH_STATUS_REQUEST && this.props.logoutAPI.fetchStatus === FETCH_STATUS_SUCCESS) {
      this.hideLoading()
    }
  }

  _setLoadingUserEmailErrorText() {
    this.setState({
      showErrorText: true,
      errorText: '',
      shouldDisabled: true
    })
  }

  _setShowErrorSetPasswordFailed() {
    this.setState({
      showErrorText: true,
      errorText: this.getMessage('set_password_failed')
    })
  }

  setShowModalSuccess() {
    this.setState({
      showModal: true,
      modalData: {
        title: this.getMessage('set_pass_success_title'),
        message: this.getMessage('set_pass_success_msg')
      }
    })
  }

  getMessage = (message) => {
    const browserLang = navigator.language.toUpperCase()
    const lang = ['EN', 'TH'].includes(browserLang) ? browserLang : 'EN'
    return MESSAGE[lang][message]
  }

  handleRedirectLogin() {
    /*istanbul ignore next */
    setTimeout(() => {
      this.props.history.push(FRONTEND_ROUTES.loginRoute)
    }, 1000)
  }

  _enableInputAndButton() {
    this.setState({
      shouldDisabled: false
    })
  }

  resetInput() {
    this.newPassword.value = ''
    this.confirmPassword.value = ''
  }

  validatePassword() {
    const newPassword = this.newPassword.value.trim()
    const confirmPassword = this.confirmPassword.value.trim()
    return (
      newPassword.length >= REQUIRE_PASSWORD_LENGTH &&
      confirmPassword.length >= REQUIRE_PASSWORD_LENGTH &&
      REGEX_PASS.test(newPassword) &&
      newPassword === confirmPassword
    )
  }

  setShowErrorText(error) {
    this.setState({
      showErrorText: true,
      errorText: error
    })
  }

  checkRegExPassword(password) {
    const regex = new RegExp(REGEX_PASS)
    return regex.test(password)
  }

  handleShowErrorInvalidPassword() {
    const newPassword = this.newPassword.value.trim()
    const confirmPassword = this.confirmPassword.value.trim()
    let msg = ''
    if (
      newPassword.length < REQUIRE_PASSWORD_LENGTH ||
      confirmPassword.length < REQUIRE_PASSWORD_LENGTH ||
      !this.checkRegExPassword(newPassword) ||
      !this.checkRegExPassword(confirmPassword)
    ) {
      msg = this.getMessage('wrong_password_format')
    }
    if (newPassword !== confirmPassword) {
      msg = this.getMessage('password_not_match')
    }
    if (newPassword === '' || confirmPassword === '') {
      msg = this.getMessage('blank_password')
    }
    this.setShowErrorText(msg)
  }

  handleSetPasswordFailed() {
    this.hideLoading()
    this.resetInput()
  }

  hideLoading() {
    this.setState({
      showLoading: false,
      shouldDisabled: false
    })
  }

  showLoading() {
    this.setState({
      showLoading: true
    })
  }

  handleSetPasswordClicked() {
    if (!this.validatePassword()) {
      this.handleShowErrorInvalidPassword()
      this.handleSetPasswordFailed()
    } else {
      this.setState({
        showErrorText: false,
        errorText: ''
      })
      const registerToken = this.props.location.pathname.split('/set-password/')[1]
      this.props.setPassword(registerToken, this.confirmPassword.value.trim())
    }
  }

  handleInputPressed(e) {
    if (e.key === 'Enter') {
      this.handleSetPasswordClicked()
    }
  }

  getErrorText() {
    let output = <div className="error-wrapper text-style">{this.state.errorText}</div>
    return output
  }

  getUserEmail() {
    return <div className="email-text text-style">{this.props.userEmail}</div>
  }

  getSuggestSetPasswordText() {
    const msg = this.getMessage('password_hint')
    return <p className="suggest-text text-style">{msg}</p>
  }

  getLogo() {
    return <img src={LogoImg} alt="logo" />
  }

  getSetPasswordForm() {
    return (
      <div className="form-wrapper">
        <div className="input-form-wrapper">
          <TextInput
            id="new-password-input"
            passRef={(ref) => (this.newPassword = ref)}
            type="password"
            placeHolder={this.getMessage('password_placeholder')}
            onKeyPress={(e) => this.handleInputPressed(e)}
            label={this.getMessage('password_label')}
            errorText={this.state.showErrorText ? ' ' : ''}
            disabled={this.state.shouldDisabled}
          />
        </div>
        <div className="input-form-wrapper">
          <TextInput
            id="confirm-password-input"
            passRef={(ref) => (this.confirmPassword = ref)}
            type="password"
            placeHolder={this.getMessage('confirm_password_placeholder')}
            onKeyPress={(e) => this.handleInputPressed(e)}
            label={this.getMessage('confirm_password_label')}
            errorText={this.state.showErrorText ? ' ' : ''}
            disabled={this.state.shouldDisabled}
          />
        </div>
        {this.getErrorText()}
        <div className="button-form-wrapper">
          <Button
            id="set-password-btn"
            text={this.getMessage('confirm_btn')}
            onClick={() => (this.state.shouldDisabled ? null : this.handleSetPasswordClicked())}
            disabled={this.state.shouldDisabled}
          />
        </div>
      </div>
    )
  }

  getLoading() {
    let output = null
    if (this.state.showLoading) {
      output = (
        <ModalOverlay>
          <Spinner />
        </ModalOverlay>
      )
    }
    return output
  }

  getModal() {
    let output = null
    if (this.state.showModal) {
      output = <ModalNotification className={'set-pass-success-modal'} title={this.state.modalData.title} message={this.state.modalData.message} />
    }
    return output
  }

  getSetPasswordContent() {
    return (
      <>
        {this.getUserEmail()}
        {this.getSuggestSetPasswordText()}
        {this.getSetPasswordForm()}
      </>
    )
  }

  getLinkInvalidText() {
    const title = this.getMessage('invalid_link_1')
    const message = this.getMessage('invalid_link_2')
    return (
      <div className="invalid-link-text">
        <p>{title}</p>
        <p>{message}</p>
      </div>
    )
  }

  getContent() {
    let output = this.getSetPasswordContent()
    if (this.state.showErrorText && this.props.errorCode === NOT_FOUND_ERROR_CODE) {
      output = this.getLinkInvalidText()
    }
    return output
  }

  handleBackToHomeBtnClicked = () => {
    this.props.history.push(FRONTEND_ROUTES.homeRoute)
  }

  handleSignoutBtnClicked = () => {
    this.props.logout()
  }

  getUserLoggedInModal() {
    let output = null
    if (this.props.currentUser) {
      const { email } = this.props.currentUser
      const title = this.getMessage('logged_in_title')
      const message = `${this.getMessage('logged_in_msg_1')} "${email}". ${this.getMessage('logged_in_msg_2')}`
      const backBtn = this.getMessage('back_to_home_btn')
      const signOutBtn = this.getMessage('sign_out_btn')
      output = (
        <ModalNotification
          title={title}
          message={message}
          onSecondaryButtonClick={this.handleBackToHomeBtnClicked}
          onPrimaryButtonClick={this.handleSignoutBtnClicked}
          secondaryButtonText={backBtn}
          primaryButtonText={signOutBtn}
        />
      )
    }
    return output
  }

  render() {
    return (
      <SetPasswordStyled>
        <div className="set-password-wrapper">
          {this.getLogo()}
          {this.getContent()}
        </div>
        {this.getLoading()}
        {this.getModal()}
        {this.getUserLoggedInModal()}
      </SetPasswordStyled>
    )
  }
}
/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    setPasswordStatus: state.setPasswordPage.setPasswordStatus.fetchStatus,
    getUserEmailStatus: state.setPasswordPage.getUserEmailStatus.fetchStatus,
    userEmail: state.setPasswordPage.userEmail,
    errorCode: state.setPasswordPage.errorCode,
    currentUser: state.loginPage.loginAPI.userData,
    logoutAPI: state.loginPage.logoutAPI
  }
}
/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, setPasswordActions, LoginActions), dispatch)
}

SetPasswordPage.propsTypes = {
  userEmail: PropTypes.string.isRequired,
  setPasswordStatus: PropTypes.string,
  getUserEmailStatus: PropTypes.string,
  errorCode: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordPage)
